@import url("https://fonts.googleapis.com/css2?family=ADLaM+Display&family=Abel&display=swap");

body {
  overflow-x: hidden;
}

.main {
  max-width: 1100px;
  margin: auto;
}

.nav {
  width: 100vw;
  display: flex;
  height: 125px;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #f3f5f9;
  z-index: 1000;
  padding: 0 50px;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.1);
}

.menuMobile {
  display: none !important;
}

.ul {
  list-style: none;
  display: flex;
  gap: 24px;
  font-size: 20px;
  margin: 0;
}

.ul li {
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

.ul li a {
  text-decoration: none;
  color: black;
  font-family: "Abel", sans-serif;
}

.ul li:hover {
  cursor: pointer;
  border-bottom: 2px solid #550450;
}

.button {
  font-size: 20px;
  border-radius: 40px;
  padding: 11px 40px;
  transition: all 0.3s;
  width: fit-content;
  font-family: "Abel", sans-serif;
}

.buttonNav {
  background-color: transparent;
  color: #550450;
  border: 1px solid #550450;
}

.buttonNav:hover {
  background-color: #550450;
  color: #fff;
}

.buttonWhite {
  background-color: #fff;
  color: #550450;
  border: none;
}

.buttonWhite:hover {
  background-color: #550450;
  color: #fff;
}

.buttonPurple {
  background-color: #550450;
  color: #fff;
  border: 1px solid #550450;
}

.buttonPurple:hover {
  background-color: #fff;
  color: #550450;
}

.firstSection {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  display: flex;
  align-items: center;
  height: 100vh;
  background-position: center;
  background-size: cover;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/images/download.jpg");
  background-position: center;
  background-size: cover;
  opacity: 0.8;
  z-index: -1;
}

.containerFirstSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: auto;
}

.speakContainer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  color: #fff;
  max-width: 40%;
}

.secondSection {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  text-align: center;
}

.thirdSection {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  background-color: #eae3f9;
  padding: 50px 0;
  text-align: center;
}

.container {
  max-width: 1100px;
}

.thirdSection h2 {
  color: #4c0065;
  font-size: 3rem;
  max-width: 550px;
  padding-bottom: 50px;
  margin: auto;
  font-family: "ADLaM Display", sans-serif;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4c0065;
  border-radius: 40px;
  color: #fff;
  width: 50%;
  height: 150px;
  padding: 20px;
  box-shadow: 6px 6px 15px 0px rgba(0, 0, 0, 0.5);
}

.card1 {
  align-self: start;
  position: relative;
}

.card2 {
  align-self: end;
  position: relative;
}

.card1 img {
  position: absolute;
  width: 171px;
  top: 130px;
  left: 0;
}

.card2 img {
  position: absolute;
  width: 223px;
  bottom: 150px;
  z-index: -1;
  right: 30px;
}

.card p {
  font-size: 18px;
  font-family: "Abel", sans-serif;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 260px;
  border: 3px solid #550450;
  border-radius: 40px;
  border-end-start-radius: 0;
  box-shadow: 6px 6px 15px 0px rgba(0, 0, 0, 0.5);
  transition: transform 0.6s ease;
}

.cardContainer:hover img {
  transform: rotateY(180deg);
}

.cardContainer img {
  transition: transform 0.6s ease;
}

.fourthSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.fourthSection div {
  width: 50%;
}

.textContainerFourth {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.textContainerFourth h2 {
  font-size: 48px;
  text-align: left;
  color: #550450;
  font-family: "ADLaM Display", sans-serif;
}

.textContainerFourth p {
  font-size: 20px;
  text-align: left;
  font-family: "Abel", sans-serif;
}

.fifthSection {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #eae3f9;
  padding: 50px 0;
  text-align: center;
}

.textContainerFifth {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 30px;
}

.textContainerFifth h2 {
  font-size: 48px;
  text-align: left;
  color: #550450;
  font-family: "ADLaM Display", sans-serif;
}

.textContainerFifth p {
  font-size: 20px;
  text-align: left;
  font-family: "Abel", sans-serif;
}

.sixthSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.sixthSection div {
  width: 50%;
}

.footer {
  background-color: #eae3f9;
  display: flex;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 170px;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1100px;
  margin: auto;
}

.version {
  font-family: 'Abel', sans-serif;
  text-align: center;
  font-size: 14px;
}

.logo {
  font-size: 36px;
  cursor: pointer;
}

.logo a {
  color: #550450;
  text-decoration: none;
  font-family: "ADLaM Display", sans-serif;
}

.font48 {
  font-size: 48px;
  text-align: left;
  font-family: "ADLaM Display", sans-serif;
}

.font24 {
  font-size: 20px;
  color: #550450;
  font-family: "ADLaM Display", sans-serif;
}

.font20 {
  font-size: 20px;
  font-family: "Abel", sans-serif;
}

.width50 {
  width: 50%;
}

.menuMobileIcon {
  display: none !important;
  cursor: pointer;
  color: #550450;
}

.mobileMenu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 250px;
  height: 100%;
  background-color: #f3f5f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 100;
}

.mobileMenu.open {
  right: 0;
}

.mobileMenu ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.mobileMenu ul li {
  margin-bottom: 20px;
}

.mobileMenu ul li a {
  text-decoration: none;
  color: #550450;
  font-size: 20px;
}

.buttonMobile {
  background-color: #550450;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 18px;
}

.buttonMobile:hover {
  background-color: #fff;
  color: #550450;
  border: 1px solid #550450;
}

@media (max-width: 768px) {
  .main {
    max-width: 320px;
  }

  .nav {
    height: 75px;
  }

  .menuMobileIcon {
    display: flex !important;
  }

  .ul,
  .buttonNav {
    display: none;
  }

  .nav ul {
    display: none;
  }

  .nav button {
    display: none;
  }

  .containerFirstSection {
    max-width: 320px;
    flex-direction: column;
  }

  .containerFirstSection img {
    width: 320px;
    margin-top: 30px;
  }

  .speakContainer {
    max-width: 100%;
  }

  .secondSection {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .cardContainer {
    width: 50%;
    margin-bottom: 20px;
  }

  .container {
    max-width: 320px;
  }

  .thirdSection {
    height: 100vh;
  }

  .card {
    width: 100%;
    height: auto;
  }

  .card1 img {
    display: none;
  }

  .card2 img {
    display: none;
  }

  .width50 {
    display: none;
  }

  .cards {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .fourthSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
  }

  .fourthSection div img {
    width: 250px;
  }

  .fourthSection div {
    width: 100%;
  }

  .fifthSection {
    height: auto;
  }

  .textContainerFifth {
    width: 100%;
  }

  .sixthSection {
    flex-direction: column;
    height: auto;
    margin: 50px 0;
  }

  .sixthSection div {
    width: 100%;
  }

  .sixthSection div img {
    width: 320px;
  }

  .footer {
    height: auto;
    text-align: center;
  }

  .footerContainer {
    flex-direction: column;
    width: 320px;
    padding: 30px 0;
  }
}
